<template>
  <div class="response success fadein" ref="response" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="errors" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <div class="loader" v-if="loading"></div>
  <form
    @submit.prevent="form_submit"
    class="form"
    data-gw-tab="unbind"
    data-gw-tab-grp="form"
    ref="form"
  >
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Server</div>
      </div>
      <select
        name="server"
        class="tt-uppercase"
        v-model="server"
        data-shop-servers
        translate="no"
      >
        <option v-for="(s, index) in servers" :key="index" :value="s.id">
          {{ s.name }} x{{ s.rate }}
        </option>
      </select>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">Character</div>
      </div>
      <template v-if="logged_in && characters[server].length">
        <select
          name="character"
          v-model="character"
          class="tt-uppercase"
          ref="account_character_list"
          required
        >
          <option
            v-for="(c, index) in characters[server]"
            :key="index"
            :value="c.char_name"
            translate="no"
          >
            {{ c.char_name }}
          </option>
        </select>
      </template>
      <template v-else>
        <input
          type="text"
          class="input"
          name="character"
          v-model="character"
          required
        />
      </template>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__mail.png" alt="" />
        </div>
        <div class="field__title">E-mail</div>
      </div>
      <input name="email" type="email" class="input" required />
    </div>
    <!-- END  field -->

    <div class="protection protection_mb">
      <div class="protection__captcha">
        <vue-recaptcha
          v-if="showRecaptcha"
          :sitekey="recaptcha_sitekey"
          size="normal"
          theme="dark"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </div>
    </div>

    <div class="form__btn-box">
      <button
        class="button"
        :class="{ disabled: btn_disabled }"
        :disabled="btn_disabled"
        type="submit"
      >
        <span class="button__content">Unbind</span>
      </button>
    </div>
  </form>
</template>

<script>
import { recaptcha_sitekey } from "@/assets/js/recaptcha";
import vueRecaptcha from "vue3-recaptcha2";
import axios from "axios";
import cfg_axios from "@/config/cfg_axios";

export default {
  name: "Unbind",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: false,
      btn_disabled: false,
      errors: [],
      response: null,
      form: {
        email: null,
        character: null,
        server: null,
      },
      loading: false,
    };
  },
  created() {
    this.form.server = this.get_default_server;
  },
  computed: {
    get_default_server() {
      return this.$store.getters.get_default_server;
    },
    servers() {
      return this.$store.getters.get_servers;
    },
    character: {
      get() {
        return this.form.character;
      },
      set(value) {
        this.form.character = value;
      },
    },
    server: {
      get() {
        return this.form.server;
      },
      set(value) {
        this.form.server = value;
      },
    },
    characters() {
      return this.$store.getters.get_characters;
    },
    logged_in() {
      return this.$store.getters.get_logged_in;
    },
  },
  methods: {
    recaptchaVerified() {
      this.captcha = true;
    },
    recaptchaExpired() {
      this.captcha = false;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.captcha = false;
    },
    async form_submit() {
      this.loading = true;
      this.btn_disabled = true;
      this.errors = [];
      this.response = null;

      if (!this.captcha) {
        this.errors.push("You should verify captcha");
        setTimeout(() => {
          this.btn_disabled = false;
          this.errors = [];
        }, 3000);
        this.loading = false;
        return;
      }

      if (this.errors.length > 0) {
        this.loading = false;
        return;
      }

      const form_data = new FormData(this.$refs.form);

      await axios
        .post(cfg_axios.base_url + "/account/unbind", form_data, {
          headers: cfg_axios.headers,
        })
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          this.errors.push(
            error.response.data.messages[
              Object.keys(error.response.data.messages)[0]
            ]
          );
        })
        .finally(() => {
          this.recaptchaExpired();
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
            this.response = null;
          }, 10000);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
